<template>
  <div class="flex justify-center h-full w-full">
    <div class="w-full sm:w-max-3xl">
      <div v-if="games" class="w-full flex flex-col gap-4">
        <div class="flex justify-between w-full">
          <span class="text-2xl font-bold">Games</span>
          <vs-button to="/game-create" class="ml-2"> Create Game </vs-button>
        </div>

        <GameTable :games="games" />
      </div>
    </div>
  </div>
</template>

<script>
import { getAllGamesByAdmin } from "@/services/ApiService";
import GameTable from "@/views/admin/game/GameTable";

export default {
  name: "Games",
  components: { GameTable },
  data() {
    return {
      games: undefined,
      name: "",
      gamesCreateLoading: false,
    };
  },
  methods: {
    async getAllGames() {
      const loading = this.$vs.loading({
        type: "points",
        background: "rgba(0, 0, 0, 0.6)",
        color: "primary",
      });
      const games = await getAllGamesByAdmin();
      if (games) {
        this.games = games;
      } else {
        this.games = null;
      }
      loading.close();
    },
  },
  async created() {
    await this.getAllGames();
  },
};
</script>

<style scoped>

</style>
